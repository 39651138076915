<template>
  <!-- 虚拟仿真实验详情 -->
  <div class="co_contain">
    <div class="contain_up">
      <div class="main-title">
        <div class="main-title-contain">
          <div class="title-left">
            <video
              :src="videourl"
              :poster="posterimg"
              controls
              controlslist="nodownload"
              width="500"
              height="315"
              @contextmenu.prevent="handleRMouse"
            />
          </div>
          <div class="title-right">
            <div class="title-text">{{ vrinfo.name }}</div>
            <div class="intro-contain">
              <div class="item">
                <el-image class="elimage" :src="icon1" />
                <div class="nametitle">负责人:</div>
                <div class="name">{{ vrinfo.leader_name }}</div>
              </div>
              <div class="item">
                <el-image class="elimage2" :src="icon2" />
                <div class="nametitle">所属学科:</div>
                <div class="name">{{ vrinfo.subject_name }}</div>
              </div>
            </div>
            <div class="num_count_con">
              <div class="num_item_con">
                <div class="text_num1">{{ vrinfo.view_count }}</div>
                <div class="text_num2">累计访问量</div>
              </div>
              <div class="num_item_con">
                <div class="text_num1">{{ vrinfo.join_count }}</div>
                <div class="text_num2">累计参与人次</div>
              </div>
              <!-- <div class="num_item_con">
                <div class="text_num1">{{ vrinfo.collect_count }}</div>
                <div class="text_num2">累计收藏数</div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="main-title-bt">
          <div class="left_con">
            <div :class="tabbtid === 0 ? 'bt btactive' : 'bt'" @click="tabbt(0)">项目简介视频</div>
            <div
              :class="tabbtid === 1 ? 'bt bt_right btactive' : 'bt bt_right'"
              @click="tabbt(1)"
            >项目引导视频</div>
          </div>
          <div class="right_con">
            <div class="collect_con" @click="collectbt()">
              <!-- <template v-if="vrinfo.is_collect === 0">
                <el-image class="elimage" :src="collecticon1" />
                <div class="text">收藏</div>
              </template>
              <template v-if="vrinfo.is_collect === 1">
                <el-image class="elimage" :src="collecticon2" />
                <div class="text">已收藏</div>
              </template>-->
            </div>
            <!--标书要求-->
            <!-- <el-button type="primary" class="r_bt" @click="LongRangePerimental">远程实验指导</el-button> -->
            <el-button type="primary" class="r_bt" @click="toexperimental">进入实验</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="bottom-contain">
        <div class="left-bts">
          <div
            :class="activebtid === index ? 'bt btactive' : 'bt'"
            v-for="(item, index) in btlist"
            :key="index"
            @click="clickbt(index, item)"
          >
            <template v-if="activebtid === index">
              <el-image class="bt-icon" :src="item.icon2" />
              <div class="bt-text bt-text_active">{{ item.name }}</div>
            </template>
            <template v-else>
              <el-image class="bt-icon" :src="item.icon" />
              <div class="bt-text">{{ item.name }}</div>
            </template>
          </div>
        </div>

        <div class="right-contain">
          <component :is="componentId" ref="mycomponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth"
import { getVRInfo } from "@/api/vr"
import { setuserfavorite } from "@/api/userfavorite"
import Projectintroduction from "./components/projectintroduction.vue"
import Projectteam from "./components/projectteam.vue"
import Experimentguide from "./components/experimentguide.vue"
import Experimentalresources from "./components/experimentalresources.vue"
import Learningrecord from "./components/learningrecord.vue"
import Learningmaterials from "./components/learningmaterials.vue"
import Dataanalysis from "./components/dataanalysis.vue"
import Interactiveanswer from "./components/interactiveanswer.vue"
import Applicationmaterials from "./components/applicationmaterials.vue"
export default {
  name: "courselearning",
  components: {
    Projectintroduction,
    Projectteam,
    Experimentguide,
    Experimentalresources,
    Learningrecord,
    Learningmaterials,
    Dataanalysis,
    Interactiveanswer,
    Applicationmaterials,
  },

  data () {
    return {
      token: getToken() ? true : false,
      videourl: "",
      posterimg: "", //初始图片
      tabbtid: 0,
      activebtid: 0,
      vr_ilabx_token_id: "", //ilab-x连接上传密钥
      componentId: "Projectintroduction",
      returncomid: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/newchange/responsible.png"),
      icon2: require("@/assets/newchange/subject.png"),
      icon3: require("@/assets/coursedetails/访问.png"),
      icon4: require("@/assets/coursedetails/人数.png"),
      icon5: require("@/assets/coursedetails/收 藏.png"),
      icon6: require("@/assets/coursedetails/图层 59.png"),
      collecticon1: require("@/assets/newchange/collection.png"),
      collecticon2: require("@/assets/newchange/collect_22.png"),
      btlist: [
        {
          name: "项目介绍",
          icon: require("@/assets/newchange/1.png"),
          icon2: require("@/assets/newchange/1.1.png"),
          component: "Projectintroduction",
        },
        {
          name: "项目团队",
          icon: require("@/assets/newchange/2.png"),
          icon2: require("@/assets/newchange/2.1.png"),
          component: "Projectteam",
        },
        {
          name: "实验指南",
          icon: require("@/assets/newchange/3.png"),
          icon2: require("@/assets/newchange/3.1.png"),
          component: "Experimentguide",
        },
        {
          name: "实验资源",
          icon: require("@/assets/newchange/4.png"),
          icon2: require("@/assets/newchange/4.1.png"),
          component: "Experimentalresources",
        },
        {
          name: "学习记录",
          icon: require("@/assets/newchange/5.png"),
          icon2: require("@/assets/newchange/5.1.png"),
          component: "Learningrecord",
        },
        {
          name: "学习资料",
          icon: require("@/assets/newchange/6.png"),
          icon2: require("@/assets/newchange/6.1.png"),
          component: "Learningmaterials",
        },
        // {
        //   name: "数据分析",
        //   icon: require("@/assets/newchange/7.png"),
        //   icon2: require("@/assets/newchange/7.1.png"),
        //   component: "Dataanalysis",
        // },
        {
          name: "互动问答",
          icon: require("@/assets/newchange/8.png"),
          icon2: require("@/assets/newchange/8.1.png"),
          component: "Interactiveanswer",
        },
        {
          name: "申报材料",
          icon: require("@/assets/newchange/9.png"),
          icon2: require("@/assets/newchange/9.1.png"),
          component: "Applicationmaterials",
        },
        //标书要求
        // {
        //   name: "项目介绍",
        //   icon: require("@/assets/newchange/1.png"),
        //   icon2: require("@/assets/newchange/1.1.png"),
        //   component: "Projectintroduction",
        // },
        // {
        //   name: "项目团队",
        //   icon: require("@/assets/newchange/2.png"),
        //   icon2: require("@/assets/newchange/2.1.png"),
        //   component: "Projectteam",
        // },
        // {
        //   name: "实验指南",
        //   icon: require("@/assets/newchange/3.png"),
        //   icon2: require("@/assets/newchange/3.1.png"),
        //   component: "Experimentguide",
        // },
        // {
        //   name: "实验练习",
        //   icon: require("@/assets/newchange/4.png"),
        //   icon2: require("@/assets/newchange/4.1.png"),
        //   component: "Experimentalresources",
        // },
        // {
        //   name: "实验考试",
        //   icon: require("@/assets/newchange/5.png"),
        //   icon2: require("@/assets/newchange/5.1.png"),
        //   component: "Learningrecord",
        // },
        // {
        //   name: "成绩分析",
        //   icon: require("@/assets/newchange/7.png"),
        //   icon2: require("@/assets/newchange/7.1.png"),
        //   component: "Dataanalysis",
        // },
        // {
        //   name: "资料库",
        //   icon: require("@/assets/newchange/6.png"),
        //   icon2: require("@/assets/newchange/6.1.png"),
        //   component: "Learningmaterials",
        // },
        // {
        //   name: "互动问答",
        //   icon: require("@/assets/newchange/8.png"),
        //   icon2: require("@/assets/newchange/8.1.png"),
        //   component: "Interactiveanswer",
        // },
        // {
        //   name: "申报材料",
        //   icon: require("@/assets/newchange/9.png"),
        //   icon2: require("@/assets/newchange/9.1.png"),
        //   component: "Applicationmaterials",
        // },
      ],
      vrinfo: {},
      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
    }
  },
  created () {
    this.vr_ilabx_token_id = decodeURIComponent(
      this.$route.query.vr_ilabx_token_id
    )
    localStorage.setItem(
      "vr_ilabx_token_id",
      this.$route.query.vr_ilabx_token_id
    )
    this.getvrinfo({ id: parseInt(this.$route.query.id) })
    this.activebtid = this.$route.query.btid ? this.$route.query.btid : 0
    this.returncomid = this.$route.query.returncomid
      ? this.$route.query.returncomid
      : this.componentId
  },
  provide () {
    return {
      vr_id: parseInt(this.$route.query.id),
    }
  },
  mounted () { },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    randomimage () {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ]
      let item = items[Math.floor(Math.random() * items.length)]
      return item
    },
    tabbt (index) {
      this.tabbtid = index
      if (index == 0) {
        this.videourl = this.vrinfo.intro_video ? this.vrinfo.intro_video : ""
      } else if (index == 1) {
        this.videourl = this.vrinfo.guide_video ? this.vrinfo.guide_video : ""
      }
    },
    collectbt () {
      if (this.token) {
        this.setuserfavorite({ id: parseInt(this.$route.query.id), type: 0 })
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            })
          })
          .catch(() => { })
      }
    },
    setuserfavorite (params) {
      setuserfavorite(params)
        .then((response) => {
          if (response.code === 0) {
            this.getvrinfo({ id: parseInt(this.$route.query.id) })
            this.activebtid = 0
            this.componentId = 'Projectintroduction'
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    getvrinfo (params) {
      getVRInfo(params)
        .then((response) => {
          if (response.code === 0 && response.data) {
            this.vrinfo = {
              brief: response.data.brief,
              collect_count: response.data.collect_count,
              college_id: response.data.college_id,
              college_name: response.data.college_name,
              course_cate_config_id: response.data.course_cate_config_id,
              course_list: response.data.course_list,
              cover: response.data.cover,
              guide: response.data.guide,
              guide_video: response.data.guide_video,
              id: response.data.id,
              intro_video: response.data.intro_video,
              join_count: response.data.join_count,
              leader: response.data.leader,
              leader_name: response.data.leader_name,
              manager_list: response.data.manager_list,
              name: response.data.name,
              subject_name: response.data.subject_name,
              view_count: response.data.view_count,
              is_collect: response.data.is_collect,
            }

            this.videourl = this.vrinfo.intro_video
              ? this.vrinfo.intro_video
              : ""
            this.posterimg = this.vrinfo.cover ? this.vrinfo.cover : ""
          }
          this.clickbt(parseInt(this.activebtid), {
            component: this.returncomid,
          })
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    clickbt (index, item) {
      this.activebtid = index
      this.componentId = item.component
      // //console.log("item", item);
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(this.vrinfo)
      })
    },
    LongRangePerimental () {
      this.$router.push({
        path:
          "/home/virtualsimulationexperiment/RemoteGuidance?action=1&id=" +
          this.vrinfo.id,
      })
    },
    toexperimental () {
      // //console.log("this.componentId", this.componentId);
      this.activebtid = 3
      this.componentId = "Experimentalresources"
    },
  },
};
</script>
<style scoped lang="scss">
.co_contain {
  .contain_up {
    background: url(~@/assets/newchange/detail_bg_1.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .main-title {
      width: 1200px;
      .main-title-contain {
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 4px;
        padding: 10px 10px 0px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title-left {
          .elimage {
            width: 500px;
            height: 304px;
          }
        }
        .title-right {
          margin-left: 100px;
          flex-grow: 1;
          flex-shrink: 1;
          .title-text {
            margin-top: 10px;
            font-size: 24px;
            font-weight: 400;
            color: #ffffff;
          }
          .intro-contain {
            margin-top: 44px;
            display: flex;
            justify-content: flex-start;
            align-content: center;
            .item {
              margin-right: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #ffffff;
              .elimage {
                width: 17px;
                height: 15px;
              }
              .elimage2 {
                width: 20px;
                height: 18px;
              }
              .nametitle {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
              }
              .name {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
          .num_count_con {
            margin-top: 90px;
            padding-left: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .num_item_con {
              margin-right: 100px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .text_num1 {
                font-size: 30px;
                font-weight: 500;
                color: #ffffff;
              }
              .text_num2 {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                white-space: nowrap;
              }
            }
          }
          .position-icon {
            margin-top: 60px;
            height: 160px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .item {
                .up {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  .uptext {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #288add;
                  }
                  .uptext2 {
                    color: #dc6868;
                  }
                  .uptext3 {
                    color: #eaa41e;
                  }
                }

                .bottom {
                  margin: 0px;
                  padding: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;

                  .text {
                    font-size: 40px;
                    font-weight: 400;
                    color: #288add;
                  }
                }
              }
              .itemline {
                margin-left: 60px;
                margin-right: 60px;
                height: 117px;
                border-right: 1px solid #e7e7e7;
              }
            }
            .right {
              width: 230px;
              height: 160px;

              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              .bt1 {
                margin-right: 20px;
                width: 130px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #3d84ff;
                border-radius: 4px;
                color: white;
                cursor: pointer;
                user-select: none;
              }
              .bt2 {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 130px;
                height: 40px;
                background: #f7f7f7;
                border: 1px solid #2fbd56;
                border-radius: 4px;
                cursor: pointer;
                user-select: none;
                .elimage {
                  margin-right: 4px;
                  width: 21px;
                  height: 21px;
                }
                .text {
                  font-size: 20px;
                  font-weight: 400;
                  color: #2fbd56;
                }
              }
            }
          }
        }
      }
      .main-title-bt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 10px;
        .left_con {
          width: 300px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .bt {
            margin-left: 10px;
            width: 140px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #ffffff;
            border-radius: 5px;
            user-select: none;
            cursor: pointer;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
          }
          .btactive {
            background: #ffffff;
            color: #3d84ff;
          }
          .bt_right {
            margin-left: 20px;
          }
        }
        .right_con {
          flex: 1;
          margin-left: 140px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .collect_con {
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            .text {
              margin-left: 6px;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .r_bt {
            height: 40px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }
  .contain_down {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: url(~@/assets/newchange/detail_bg_2.png) no-repeat;
    background-size: 100% 100%;
    .bottom-contain {
      margin-top: 30px;
      width: 1200px;
      display: flex;
      justify-content: flex-start;
      // align-items: flex-start;
      align-items: stretch;
      margin-bottom: 20px;
      .left-bts {
        min-width: 220px;
        min-height: 530px;
        background: #ffffff;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 160px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          cursor: pointer;
          margin-bottom: 20px;
          &:hover {
            background: #3d84ff54;
            border-radius: 5px;
          }
          .bt-icon {
            margin-right: 10px;
          }
          .elimage {
            margin-right: 10px;
          }
          .bt-text {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
          .bt-text_active {
            color: #ffffff;
          }
        }
        .btactive {
          background: #3d84ff;
          border-radius: 5px;
        }
      }

      .right-contain {
        margin-left: 30px;

        background: #ffffff;
        flex: 1;
        min-height: 550px;
        padding: 10px;
        width: 100%;
      }
    }
  }
}
</style>
