import { render, staticRenderFns } from "./index.vue?vue&type=template&id=01038d98&scoped=true&header_flag=1"
import script from "./index.vue?vue&type=script&lang=js&header_flag=1"
export * from "./index.vue?vue&type=script&lang=js&header_flag=1"
import style0 from "./index.vue?vue&type=style&index=0&id=01038d98&prod&scoped=true&lang=scss&header_flag=1"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01038d98",
  null
  
)

export default component.exports